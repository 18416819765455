
































import CustomFieldsModal from "./CategoryCustomFieldsModal.vue";
import CustomFieldsTable from "./CategoryCustomFieldsTable.vue";
import {
  Component,
  ProvideReactive,
  Prop,
  Vue,
  Watch,
  Emit,
} from "vue-property-decorator";
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from "vuex";
import { eventHandler, translations } from "@/mixins";
import { Settings, Menu } from "@/services/SOLO";
import { AttrCustomField } from "@/models";

@Component({
  components: {
    CustomFieldsModal,
    CustomFieldsTable,
  },
  mixins: [eventHandler, translations],
})
export default class CategoryCustomFields extends Vue {
  @Prop() itemCustomFields!: Array<AttrCustomField>;
  @Prop() globalCustomFields!: Array<AttrCustomField>;
  @Prop() categoryId!: string;
  @Prop() active!: Boolean
  open: Boolean = false;
  translate!: Function;
  modifyField: any = null;

  get mappedItemItemCustomFields() {
    return this.itemCustomFields.map((field) => {
      return field["custom-field-id"].toString();
    });
  }

  modalOpenState(open: Boolean = true) {
    this.open = open;
  }

  private onCustomFieldSave(data: any) {
    console.log('dd: ', data);
    this.$emit('custom-field:saved', data);
    this.open = false;
  }

  private onCustomFieldDeleted(data: any) {
    this.$emit('custom-field:deleted', data);
    this.open = false;
  }

  modifyCustomField(item: any) {
    console.log('CategoryCustomFields: ', item);
    this.modifyField = item;
    this.modalOpenState(true);
  }

  clearModal() {
    this.modifyField = null;
  }
}
